<template>
   <div id="mainpage" class="mx-md-4">
      <b-container fluid>
         <div v-for="n in nbPattesChiens" :key="n">
            <img class="patte" src="../assets/patte_chien.png"/>
         </div>
         <b-row>
            <b-col cols="12" md="auto">
               <div id="divlogo" class="ml-4">
                  <img id="logo" alt="Image" src="../assets/logo.jpg"/>
               </div>
               <img id="pinlogo" class="pin" alt="pin" src="../assets/pins/pin2.png" />
            </b-col>
            <b-col>
               <MenuMedaille id="menu1" Title="Accueil" :Menu="Menu1"> </MenuMedaille>

               <MenuMedaille id="menu2" Title="Education" :Menu="Menu2"> </MenuMedaille>

               <MenuMedaille id="menu3" Title="Agiliy" :Menu="Menu3"> </MenuMedaille>

               <MenuMedaille id="menu4" Title="Contacts" :Menu="Menu4"> </MenuMedaille>
            </b-col>
         </b-row>
         <b-row>
            <b-col cols="12" lg="6">
               <ardoise id="ardoise">
                  <transition name="slide-fade">
                     <router-view></router-view>
                  </transition>
               </ardoise>
            </b-col>
            <b-col cols="12" lg="6">
               <Planning id="planning"> </Planning>
            </b-col>
         </b-row>
      </b-container>
   </div>
</template>

<script>
import Ardoise from "../components/Ardoise.vue";
import MenuMedaille from "../components/MenuMedaille.vue";
import Planning from "../components/calendrier.vue";
import { gsap } from "gsap";

export default {
   name: "MainPage",
   components: { Ardoise, MenuMedaille, Planning },
   created() {
      this.nbPattesChiens = Math.floor(Math.random() * 10) + 3;
   },

   mounted() {
      gsap.set("#menu1", { top: "40px" });
      gsap.set("#menu2", { top: "65px", left: "60px" });
      gsap.set("#menu3", { top: "35px", left: "110px" });
      gsap.set("#menu4", { top: "55px", left: "140px" });
      gsap.set("#planning", { rotation: 3 });
      let self = this;
      console.log(window.innerWidth);

      [...this.$el.getElementsByClassName("patte")].forEach(function (img) {
         gsap.set(img, {
            x: self.ChiffreAleatoire(110, window.innerWidth - 110),
            y: self.ChiffreAleatoire(110, window.innerHeight - 110),
            rotation: self.ChiffreAleatoire(0, 360),
         });
      });
   },
   data() {
      return {
         nbPattesChiens: 0,
         Menu1: [
            { caption: "Tarifs", link: "/mainpage/tarifs" },
            { caption: "Aliments", link: "/mainpage/aliments" },
            { caption: "Venir", link: "/mainpage/localiser" },
         ],
         Menu2: [
            { caption: "Imformations", link: "/mainpage/educationdesc" },
            { caption: "Horaires", link: "/mainpage/educationhoraires" },
         ],
         Menu3: [
            { caption: "Informations", link: "/mainpage/agilitydesc" },
            { caption: "Horaires", link: "/mainpage/agilityhoraires" },
         ],
         Menu4: [
            { caption: "Contacts", link: "/mainpage/contacts" },
            { caption: "Venir", link: "/mainpage/localiserwaze" },
         ],
      };
   },

   methods: {
      ChiffreAleatoire(min, max) {
         return Math.floor(Math.random() * (max - min)) + min;
      },

      AfficherPageAdmin() {
         this.$router.push( { path : "/pageadministrateur"});
      },
   },
};
</script>

<style>
@font-face {
   font-family: "OvertheRainbow";
   src: url("../fonts/OvertheRainbow.ttf");
}

@font-face {
   font-family: "erasdust";
   src: url("../fonts/erasdust.ttf");
}

#ardoise {
   margin-top: 80px;
}

#divlogo {
   display: block;
   margin-top: 30px;
   height: 200px;
   width: 300px;
   z-index: 11;
   transform: rotate(-5deg);
   -ms-transform: rotate(-5deg); /* Internet Explorer */
   -moz-transform: rotate(-5deg); /* Firefox */
   -webkit-transform: rotate(-5deg); /* Safari et Chrome */
   -o-transform: rotate(-5deg); /* Opera */
}

#logo {
   height: 150px;
   width: 250px;
   box-shadow: 4px 4px 6px #777;
}

#pinlogo {
   position: absolute;
   left: 150px;
   top: 0px;
   z-index: 100;
}

.patte {
   width: 100px;
   opacity: 0.4;
   position: absolute;

   z-index: 10;
}

#planning {
   margin-top: 120px;
   max-width: 400px;
   min-height: 600px;
   margin-left: 100px;
}

@media only screen and (max-device-width: 576px) {
   #planning {
      margin-top: 30px;
      max-width: 400px;
      min-height: 600px;
      margin-left: 5px;
   }
}

.slide-fade-enter-active {
   transition: all 0.3s ease;
}
.slide-fade-leave-active {
   transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  
   opacity: 0;
}
</style>
