<template>
   <div class="texte p-2 m-2" v-html="message">
      
   </div>
</template>

<script>

import { db } from "../../firebaseInit";
import { collection, query, getDoc, where, doc, setDoc } from "firebase/firestore/lite";

export default {

   data() {
      return {
         message: "",
      };
   },
   created() {
      this.GetConfig();
   },
   methods: {
      async GetConfig() {
         const confref = doc(db, "config", "config1");
         const conf = await getDoc(confref);
         if (conf.exists()) {
            console.log(conf.data());
            this.message = conf.data().texte_acceuil.replace(/\n/g, '<br>');
            console.log(this.message);
         }
      },
   }
};


</script>

<style scoped>
.texte {
   min-height: 450px;
   font-size: 1.8em;
   line-height: 1.1;
}
</style>
