<template>
   <div class="divmessageacceuil">
      <b-card bg-variant="dark" text-variant="white" class="text-center" header="Message Page Acceuil">
         <b-card-text>
            <b-form>
               <b-form-textarea
                  v-model="message"
                  placeholder="Message page acceuil"
                  rows="3"
                  max-rows="6"
               ></b-form-textarea>
               <b-button class="ml-2 mt-2" @click="EnregistrerConfig">Enregistrer</b-button>
            </b-form>
         </b-card-text>
      </b-card>
   </div>
</template>

<script>
import { db } from "../../firebaseInit";
import { collection, query, getDoc, where, doc, setDoc } from "firebase/firestore/lite";

export default {
   name: "compmessageacceuil",
   data() {
      return {
         message: "",
      };
   },
   created() {
      this.GetConfig();
   },
   methods: {
      async GetConfig() {
         const confref = doc(db, "config", "config1");
         const conf = await getDoc(confref);
         if (conf.exists()) {
            console.log(conf.data());
            this.message = conf.data().texte_acceuil;
         }
      },
      async EnregistrerConfig() {
        
         const confref = doc(db, "config", "config1");
         await setDoc(confref, {
            texte_acceuil: this.message,
         });
      },
   },
};
</script>

<style scoped>
.divmessageacceuil {
   width: 420px;
}
</style>
