<template>
   <div class="divfermetures">
      <b-card bg-variant="dark" text-variant="white" class="text-center" header="Jours de fermeture du club">
         <b-card-text>
            <b-row>
               <b-col cols="4">
                  <b-table
                     ref="TableFermeture"
                     dark
                     striped
                     small
                     bordered
                     hover
                     :items="datefermetures"
                     :fields="fields"
                     select-mode="single"
                     @row-selected="onRowSelected"
                     selectable
                     sort-by="date"
                     :sort-compare="sortCompare"
                  ></b-table>
               </b-col>
               <b-col cols="8">
                  <div class="ml-2">
                     <b-form inline>
                        <b-form-datepicker
                           v-model="newdate"
                           class="mb-2"
                           :date-disabled-fn="dateDisabled"
                           locale="fr"
                           size="sm"
                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                           placeholder="Sélectionnez"
                           :value-as-date="true"
                        ></b-form-datepicker>
                        <b-button v-if="newdate" class="ml-3 mb-2" @click="AjouterFermeture">Ajouter</b-button>
                     </b-form>

                     <b-form class="mt-5" v-show="dateselectionne.date" inline>
                        <div>{{ dateselectionne.date }}</div>
                        <b-button class="ml-2" @click="SupprimerFernmeture">Supprimer</b-button>
                     </b-form>
                  </div>
               </b-col>
            </b-row>
         </b-card-text>
      </b-card>
   </div>
</template>

<script>
const moment = require("moment");

import { db } from "../../firebaseInit";
import { collection, query, getDocs, deleteDoc, doc, addDoc } from "firebase/firestore/lite";

export default {
   name: "compfermetures",
   data() {
      return {
         datefermetures: [],
         fields: [{ key: "date", label: "Liste" }],
         dateselectionne: { date: "" },
         newdate: undefined,
      };
   },
   created() {
      this.GetFermetures();
   },
   methods: {
      async GetFermetures() {
         this.datefermetures = [];
         const q = query(collection(db, "fermetures"));
         let self = this;
         const querySnapshot = await getDocs(q);
         querySnapshot.forEach((doc) => {
            self.datefermetures.push({ id: doc.id, date: doc.data().date });
         });
         console.log(this.datefermetures);
         this.dateselectionne = { date: "" };
         return this.datefermetures;
      },

      onRowSelected(items) {
         this.dateselectionne = items[0];
         console.log(items[0]);
      },

      dateDisabled(ymd, date) {
         const weekday = date.getDay();

         return weekday !== 6;
      },

      async SupprimerFernmeture() {
         if (this.dateselectionne != undefined && this.dateselectionne.date !== "") {
            await deleteDoc(doc(db, "fermetures", this.dateselectionne.id));
            await this.GetFermetures();
            this.dateselectionne = { date: "" };
            this.$refs.TableFermeture.refresh();
         }
      },

      async AjouterFermeture() {
         console.log(this.newdate);
         let d = moment(this.newdate).format("DD/MM/YYYY");
         console.log(d);

         const docRef = await addDoc(collection(db, "fermetures"), {
            date: d,
         });
         //recharde des dateselectionne
         await this.GetFermetures();
         this.$refs.TableFermeture.refresh();

         console.log("Document written with ID: ", docRef.id);
      },
      sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
         const a = aRow[key]; // or use Lodash `_.get()`
         const b = bRow[key];
         var momentA = moment(a, "DD/MM/YYYY");
         var momentB = moment(b, "DD/MM/YYYY");
         if (momentA > momentB) return 1;
         else if (momentA < momentB) return -1;
         else return 0;
      },
   },
};
</script>

<style scoped>
.divfermetures {
   width: 420px;
}
</style>
