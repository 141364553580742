<template>
   <div class="texte ml-2 mr-2">
      <div class="titre text-center mb-4" titre>L'agility</div>

      <div>
         <h3 class="mt-3">C’est une histoire qui commença en 1988…</h3>

         Créée par des passionnés pour offrir à tout propriétaire de chien un choix nouveau dans le panel des
         disciplines sportives ouvertes aux canidés, l’agility est un sport à part entière où maître et chien évoluent
         en toute complicité sur des parcours d’obstacles élaborés, réfléchis. Ces épreuves sont mises en place par des
         juges de la discipline, selon un règlement établi par la Fédération Cynologique Internationale (FCI), adapté
         aux spécificités de chaque pays. 
         <br>L’Agility en France, c’est environ 12000 licenciés, 900 concours par an, des
         championnats régionaux et nationaux en individuels ou par équipes, et des Équipes de France qui représentent le
         pays aux grandes manifestations internationales, et notamment le Championnat du Monde. 
         
         <h3 class="mt-3">  L’agility, c’est bouger autrement avec son chien</h3>
         
       
         Un jeu complice où maitre et chien sont associés pour arriver au bout du parcours avec
         un minimum de pénalités dans un temps défini par le juge. N’utilisant que sa voix et sa gestuelle, sans laisse
         ni collier.<br> Discipline ludique par excellence dont la base reste une éducation du maître et du chien. L’agility
         est ouverte à toutes et tous sans âge limite. L’agility, c’est un jeu d’adresse qui allie éducation,
         complicité, vélocité avec comme tous les jeux sportifs quelques règles. L’agility, c’est se maintenir en forme,
         c’est faire des rencontres toute l’année sur des terrains.
      </div>
   </div>
</template>

<script>
export default {};
</script>

<style scoped>
.texte {
   min-height: 450px;
   font-size: 0.8em;
   line-height: 1.1;
   font-weight: normal;
}

.titre {
   text-decoration: underline;
   font-size: 1.2em;
}

.textejaune {
   color: Yellow;
}
</style>
