<template>
   <div id="app">
      <router-view></router-view>
      <div class='glg'>Développé par Gaël Le Guerroué &#169;2022</div>
   </div>
</template>

<script>
export default {
   name: "App",

   data() {
      return {
         windowHeight: window.innerHeight,
         txt: "",
      };
   },
   watch: {},

   mounted() {
      this.$moment.locale('fr');
   },

   beforeDestroy() {},

   methods: {},
};
</script>

<style>
body {
   background-position: 0px 0px;
   background-color: #9a9a9a !important;
   margin: 0;
   padding: 0;
   background-image: url("./assets/fond.jpg");
   background-repeat: repeat;
   background-attachment: scroll;
   font-family: "OvertheRainbow", "Brush Script MT" !important;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
   width: 100%;
   height: 100%;
}

.glg {
   font-weight: bold ;
   color: white ;
   margin-left: 1.5rem;
   
}
</style>
