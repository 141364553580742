<template>
   <div class="texte ml-2 mr-2">
      <div class="titre text-center mb-4" titre>Horaires cours d'agility</div>

      <div>
         <b-table class="tableauhoraire" small bordered hover :items="data" thead-tr-class="tableentete"></b-table>
      </div>
   </div>
</template>

<script>
export default {
   data() {
      return {
         data: [
            { Cours: "Compétition", Jour: "Samedi", Horaire: "13h30 - 16h00" },
            { Cours: "Initiation  Débutant", Jour: "Samedi", Horaire: "16h00 - 18h00" },
         ],
      };
   },
};
</script>

<style>
.tableentete {
   color: Yellow;
   font-weight: normal !important;
}
</style>

<style scoped>
.texte {
   min-height: 270px;
   font-size: 1.1em;
   line-height: 1.1;
   font-weight: normal;
}

@media only screen and (max-device-width: 576px) {
   .texte {
      font-size: 1rem;
      line-height: 1;
      font-weight: normal;
   }
}

.titre {
   text-decoration: underline;
   font-size: 1.2em;
}

.textejaune {
   color: Yellow !important;
}

.tableauhoraire {
   border: medium solid #ffffff;
   color: White !important;
}

.tableauhoraire td,
th {
   border: thin solid #ffffff;
}

.tableauhoraire td {
   text-align: center;
}
</style>
