import Vue from "vue";
import VueRouter from "vue-router";

import Login from "../pages/desktop/login";
import Register from "../pages/desktop/register";
import MainPage from "../pages/mainpage";
import pageadmin from "../pages/desktop/pageadmin";
import pageadherents from "../pages/desktop/pageadherents";

import acceuil from "../pages/desktop/acceuil";
import tarifs from "../pages/desktop/tarif";
import localiser from "../pages/desktop/localiser";
import localiserwaze from "../pages/desktop/localiserwaze";
import aliments from "../pages/desktop/aliments";
import educationdescription from "../pages/desktop/descriptioneducation";
import horaireseducation from "../pages/desktop/horaireseducation";

import agilitydescription from "../pages/desktop/descriptionagility";
import horairesagility from "../pages/desktop/horairesagility";

import contacts from "../pages/desktop/contact";

import store from "../store/store.js";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        redirect: "/mainpage",
    },
    {
        path: "/mainpage",
        name: "MainPage",
        component: MainPage,
        children: [{
                path: "",
                component: acceuil,
            },
            {
                path: "tarifs",
                component: tarifs,
            },
            {
                path: "localiser",
                component: localiser,
            },
            {
                path: "localiserwaze",
                component: localiserwaze,
            },
            {
                path: "aliments",
                component: aliments,
            },
            {
                path: "educationdesc",
                component: educationdescription,
            },
            {
                path: "educationhoraires",
                component: horaireseducation,
            },
            {
                path: "agilitydesc",
                component: agilitydescription,
            },
            {
                path: "agilityhoraires",
                component: horairesagility,
            },
            {
                path: "contacts",
                component: contacts,
            },
        ],
    },
    {
        path: "/pageadministrateur",
        component: pageadmin,
    },
    {
        path: "/pageadherents",
        component: pageadherents,
    },
];

const router = new VueRouter({
    // mode: 'history',
    routes: routes,
});

router.beforeEach((to, from, next) => {
    console.log(store.getters.IsUserConnected);
    console.log(from, to);
    next();
    // if ((!store.getters.IsUserConnected) &&(to.name != 'pageconnexion')) next("/pageconnexion");
    // else next();
});

export default router;