<template>
   <div class="menumedaille" @mouseenter="EnterMenu" @mouseleave="LeaveMenu">
      <img alt="" class="circlemenu" :src="randomnMedail()" />
      <table>
         <tr>
            <td>
               <div class="titremenu">{{ Title }}</div>
            </td>
         </tr>
         <tr>
            <td>
               <img alt="" class="iconmenu" :src="randomnChien()" />
            </td>
         </tr>
      </table>
      <ul>
         <li v-for="item in Menu" :key="item.caption">
           <router-link :to="item.link" >{{ item.caption }}</router-link>
          
         </li>

     
      </ul>
   </div>
</template>

<script>
import { gsap } from "gsap";

export default {
   name: "MenuMedaille",
   props: {
      Title: String,
      Menu: Array,
   },
   data() {
      return {
         animation: undefined,
        
      };
   },
   created() {},
   methods: {
      randomnMedail() {
         let i = Math.floor(1 + Math.random() * 5);
         let img = require("../assets/menu/medail" + i + ".png");
         
         return img;
      },

      randomnChien() {
         let i = Math.floor(2 + Math.random() * 5);
         let img = require("../assets/menu/dog" + i + ".png");
         
         return img;
      },
      EnterMenu() {
        
         let q = gsap.utils.selector(this.$el);
         gsap.set(q(".titremenu"), { fontSize: 25 });
         this.animation = gsap.timeline();
         this.animation
            .to(q(".circlemenu"), {
               scaleX: 1.5,
               scaleY: 1.5,
               x: 0,
               y: 20,
               duration: 0.5,
               ease: "back",
            })
            .to(q("ul"), { opacity: 1, x: -20, display: "block", duration: 0.7 }, 0.5)
            .to(q(".iconmenu"), { x: -50, duration: 0.9 }, 0.5);
      },
      LeaveMenu() {
         
         let q = gsap.utils.selector(this.$el);
         if (this.animation) this.animation.kill();
         gsap.set(q(".titremenu"), { fontSize: 20 });
         gsap.to(q(".circlemenu"), {
            scaleX: 1,
            scaleY: 1,
            x: 0,
            y: 0,
            duration: 0.6,
            ease: "back",
         });
         gsap.to(q("ul"), {
            opacity: 0,
            display: "none",
            duration: 0.1,
         });
         gsap.to(q(".iconmenu"), { x: 0, duration: 0.4 });
      },
   },
};
</script>

<style scoped>
.menumedaille {
  position: relative;
  display: inline-block;
   width: 130px;
   height: 130px;
   cursor: pointer;
   z-index: 13;
   top : 50px;
}

.menumedaille table {
   position: absolute;
   top: 0px;
   left: 0px;
   width: 130px;
   height: 130px;
   border-spacing: 0px;
   border-collapse: collapse;
   border: none;
   margin: 0;
   padding: 0;
   z-index: 20;
   text-align: center;
}

.iconmenu {
   max-width: 80px;
   max-height: 80px;
   width: auto;
   height: auto;
   vertical-align: top;
   z-index: 120;
}

.circlemenu {
   position: absolute;
   top: 0px;
   left: 0px;
   width: 130px;
   height: 130px;
   opacity: 0.99;
   z-index: 2;
   border-radius: 65px;
   display: block;
}

.titremenu {
   color: #222;
   font-size: 20px;
   font-weight: bold;
   line-height: 30px;
   text-shadow: 1px 1px 1px #fff;
   z-index: 3;
   margin-top: 12px;
}
.titremenu.active {
   color: #fff;
   text-shadow: 1px 0px 1px #555;
}

.menumedaille ul {
   list-style: none;
   position: absolute;
   display: none;
   top: 50px;
   left: 75px;
   opacity: 0;
   margin: 0;
   padding: 0;
   z-index: 100;
}
.menumedaille ul li a {
   font-size: 16px;
   text-decoration: none;
   color: #222;
   padding: 3px;
   float: left;
   clear: both;
   width: 95px;
   height: 25px;
   text-shadow: 1px 1px 1px #fff;
}
.menumedaille ul li a:hover {
   background-color: #fff;
   color: #444;
   -moz-border-radius: 5px;
   -webkit-border-radius: 5px;
   border-radius: 5px;
   -moz-box-shadow: 1px 1px 4px #666;
   -webkit-box-shadow: 1px 1px 4px #666;
   box-shadow: 1px 1px 4px #666;
}
</style>
