import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        MessageErreur: "", // pour affichage dans la fenêtre des erreurs

        ShowOverlay: false, //pour afficher l'overlay
        Infosuser: undefined, // informations sur l'utilisateur connecté
        labelsdatepicker: {
            fr: {
                labelHours: "Heures",
                labelMinutes: "Minutes",
                labelSeconds: "Secondes",
                labelIncrement: "Incrémenter",
                labelDecrement: "Décrémenter",
                labelSelected: "Sélectionné",
                labelNoTimeSelected: "Pas d'heure sélectionnée",
                labelCloseButton: "Fermer",
                labelPrevDecade: "Vorheriges Jahrzehnt",
                labelPrevYear: "Année précédente",
                labelPrevMonth: "Mois précédent",
                labelCurrentMonth: "Mois actuel",
                labelNextMonth: "Mois suivant",
                labelNextYear: "Année suivante",
                labelNextDecade: "Nächstes Jahrzehnt",
                labelToday: "Aujourd'hui",

                labelNoDateSelected: "Pas de date sélectionnée",
                labelCalendar: "Calendrier",
                labelNav: "Navigation",
                labelHelp: "utilisez les touches fléchées pour changer les dates du calendrier",
            },
        },
    },

    getters: {
        IsUserConnected: (state) => {
            return state.Infosuser != undefined;
        },

        IsAdmin: (state) => {
            if (state.Infosuser) return state.Infosuser.Profil == "Admin";
            else return false;
        },


    },
    mutations: {
        SetOverlay(state, value) {
            //console.log(value);
            state.ShowOverlay = value;
        },
        SetMessageErreur(state, value) {
            // console.log(value);
            state.MessageErreur = value;
        },


        SetInfosuser(state, value) {
            //console.log(value);
            state.Infosuser = value;
        },
    },
    actions: {
        SetOverlay(context, value) {
            context.commit("SetOverlay", value);
        },

        SetMessageErreur(context, value) {
            context.commit("SetMessageErreur", value);
        },

        SetInfosuser(context, value) {
            context.commit("SetInfosuser", value);
        },
    },
    modules: {},
});