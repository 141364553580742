<template>
   <div class="calendriermp">
      <h2 id="detailplanning" class="mt-5 text-center d-inline-block w-100"><u> Calendrier</u></h2>
      <div class="date" v-for="(item, idx) in calendrier" :key="idx">
         <span>{{ item.date | moment("Do MMMM") }} : </span>
         <span v-if="item.ouvert" class="vert">Ouvert</span>
         <span v-else class="rouge">Fermé</span>
      </div>

      <img id="pin1" class="pin" alt="pin" src="../assets/pins/pin2.png" />
      <img id="pin2" class="pin" alt="pin" src="../assets/pins/pin3.png" />
   </div>
</template>

<script>
const moment = require("moment");

import { db } from "../firebaseInit";
import { collection, query, getDocs, setDoc, doc } from "firebase/firestore/lite";

export default {
   name: "calendriermp",
   data() {
      return {
         calendrier: [],
      };
   },
   created() {
      this.GetCalendrier();
   },
   methods: {
      async GetCalendrier() {
         this.calendrier = [];
         let datesfermetures = [];
         const q = query(collection(db, "fermetures"));

         const querySnapshot = await getDocs(q);
         querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let d =  moment(doc.data().date, 'DD/MM/YYYY');
            datesfermetures.push(d);
         });
         console.log(datesfermetures);


         for (let i = 0; i < 8; i++) {
            let item = {};
            item.date = moment().day(6).add(i, "week");
            item.ouvert = true;

            datesfermetures.forEach(function(d){
               if (moment(d).isSame(item.date,'day'))
                  item.ouvert = false;
            })

            this.calendrier.push(item);
         }
      },
   },
};
</script>

<style>
.calendriermp {
   font-family: "erasdust";
   background-image: url("../assets/papiers.svg");
   background-repeat: no-repeat;
   background-size: 100%;
   font-size: 1.5rem;
   color: black;
   border: none;
}

.rouge {
   color: red;
}

.vert {
   color: green;
}

.date {
   margin-top: 1.23rem;
   margin-left: 80px;
}

#pin1 {
   position: absolute;
   left: 30px;
   top: -5px;
   z-index: 100;
}

#pin2 {
   position: absolute;
   right: 30px;
   top: -5px;
   z-index: 100;
}
</style>
