<template>
   <div class="carte">
      <l-map :zoom="zoom" :center="center" :options="mapOptions">
         <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
         <l-marker :lat-lng="markerLatLng">
            <l-tooltip :options="{ permanent: false, interactive: true }">
               <div><b>Club Canin de Corps-Nuds</b></div>
               <div>La Lande du Feux</div>
               <div>Corps-Nuds</div>
                <div>47.975975, -1.555648</div>
            </l-tooltip>
         </l-marker>
      </l-map>
   </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

export default {
   components: {
      LMap,
      LTileLayer,
      LMarker,
      LTooltip,
   },
   data() {
      return {
         url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
         attribution: "&copy; Club Canin de Corps-Nuds",
         zoom: 15,
         center: [47.975975, -1.555648],
         markerLatLng: [47.975975, -1.555648],
         mapOptions: {
            zoomSnap: 0.5,
         },
      };
   },
   methods: {},
};
</script>

<style>
.map {
   overflow: hidden;
}

.carte {
   min-height: 450px;
   height: 450px;
}
</style>
