<template>
   <div id="pageadmin">
      <b-container>
         <h1>Page Admininistration</h1>
         <b-row>
            <b-col>
               <b-card-group deck>
                  <compmessageacceuil></compmessageacceuil>
                  <compfermetures> </compfermetures>
               </b-card-group>
            </b-col>
         </b-row>
      </b-container>
   </div>
</template>

<script>
import { gsap } from "gsap";
import compfermetures from "../../components/admin/fermetures.vue";
import compmessageacceuil from "../../components/admin/messageacceuil.vue";

export default {
   name: "PageAdmim",
   components: { compfermetures, compmessageacceuil },
   created() {},

   mounted() {
      console.log("mounted page admin");
   },
   data() {
      return {};
   },

   methods: {},
};
</script>

<style scoped>
#pageadmin {
   background-image: url("../../assets/ardoise.jpg");
   color: white;
   font: 1em sans-serif;
   height: 100vh;
   width: 100%;
}
</style>
