<template>
   <div class="carte">
      <iframe
         src="https://embed.waze.com/fr/iframe?
  zoom=12&lat=47.975975&lon=-1.555648&pin=1&desc=1"
         width="100%"
         height="100%"
      ></iframe>
   </div>
</template>

<script>
export default {
   components: {},
   data() {
      return {
         markerLatLng: [47.975975, -1.555648],
      };
   },
   methods: {},
};
</script>

<style>
.carte {
   min-height: 450px;
   height: 450px;
}
</style>
