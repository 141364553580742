<template>
   <div class="texte ml-2 mr-2">
      <div class="titre text-center mb-4" titre>Contacts</div>

      <div>
         <span>Pour tous renseignements vous pouvez contacter : </span>
         
         <div class="mt-3 textejaune">Gaël Le Guerroué au 06.63.72.62.08 </div>
         <div>
            <span>ou par mail : </span
            ><span
               ><a
                  class="textejaune"
                  href="mailto:contact@club-canin-corps-nuds.fr?subject=demande de renseignements&cc=galegu@yahoo.fr"
               >
                  contact@club-canin-corps-nuds.fr</a
               ></span
            >
         </div>
         <div class="mt-3">
            <div>Adresse du club : </div>

            <span class="textejaune">La Lande du Feu<br> 35150 Corps-Nuds</span>
         </div>
         <div class="mt-4">
            <a class="textejaune" href="https://plus.google.com/101116614125673662171" rel="publisher">
               <img alt="Google+" width="50px" height="50px" src="../../assets/GooglePlus.png"
            /></a>

            <a
               class="textejaune"
               href="https://www.facebook.com/pages/Club-Canin-de-Corps-Nuds/582739055069922"
               rel="publisher"
            >
               <img alt="FaceBook" width="50px" height="50px" src="../../assets/facebook.png"
            /></a>
         </div>
      </div>
   </div>
</template>

<script>
export default {};
</script>

<style scoped>
.texte {
   min-height: 450px;
   font-size: 1.1em;
   line-height: 1.1;
   font-weight: normal;
}

.titre {
   text-decoration: underline;
   font-size: 1.2em;
}

.textejaune {
   color: Yellow;
}
</style>
