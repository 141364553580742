<template>
   <div class="texte ml-2 mr-2">
      <div class="titre text-center mb-4" titre>L'éducation du chien</div>

      <div>
         <h3>Définition</h3>

         L'Education Canine cherche à créer une harmonie entre le chien et son maître. C'est la discipline où on apprend
         à notre compagnon les bases de l'éducation. On apprend également à le sociabiliser.

         <h3 class="mt-3">Présentation</h3>

         L'Education Canine est la première étape à franchir avant d'aborder toute autre discipline dans un club canin.
         Cette discipline permet d'apprendre aux chiens, quelque soit leur âge, les bases de l'éducation. Elles leur
         seront utiles dans la vie de tous les jours. Une fois ces bases acquises, le maître et son compagnon pourront
         découvrir d'autres activités telles que l'Obéissance et l'Agility. L'éducation consiste à sociabiliser votre
         animal aussi bien envers ses congénères, les membres de la famille, vos enfants ou vos amis. Cette discipline
         consiste en l'apprentissage de différents exercices à assimiler et de différents comportements à acquérir. Cet
         enseignement se fait dans la douceur et le jeu, sans brusquer l'animal. Lorsque les exercices sont bien
         exécutés le chien sera récompensé par une caresse ou une petite friandise.

         <h3 class="mt-3">Le chien en Education Canine</h3>

         L'objectif de l'Education Canine est de permettre au maître d'acquérir les bases d'éducation qu'il aura à
         transmettre à son chien. Elle permet aussi sa bonne intégration dans la société par le travail d'une série
         d'exercices dont la difficulté est progressive. Les exercices type sont les suivants : -le rappel au pied :
         l'exercice le plus important -les positions : Assis, Couché, Debout, Reste -la marche au pied avec changement
         de direction : avec ou sans laisse -l'absence du maître : position Couché, Assis -les positions à distance :
         Assis, Couché, Debout -le refus d'appâts -l'arrêt bloqué Cette liste n'est pas complète. Elle n'est qu'un
         aperçu des différents exercices que vous fera faire le moniteur. Vous débuterez avec votre chien dans le groupe
         des débutants. Quand vous aurez suffisamment progressé, vous pourrez aller dans le groupe de niveau supérieur.
         Ainsi de suite.

         <h3 class="mt-3">Sociabiliser le chien en Education Canine</h3>

         L'Education Canine fait également une grande place à la sociabilité du chien. Celui-ci est mis en présence avec
         d'autres chiens. Les chiens travailleront, en effet, ensemble sur le même terrain. Tous ces exercices
         facilitent grandement l'intégration du couple maître-chien dans la vie de tous les jours.

         <h3 class="mt-3">En conclusion ...</h3>

         L'Education Canine n'est pas seulement un travail sur le chien, elle permet aussi et surtout aux maîtres de
         mieux comprendre son animal et de pouvoir ainsi minimiser les erreurs que l'on peut faire quand on accueille un
         nouveau compagnon à 4 pattes dans son foyer. Bien appliquée, l' Education Canine est une discipline qui vous
         sera utile à vous et à votre animal durant toute sa vie.
      </div>
   </div>
</template>

<script>
export default {};
</script>

<style scoped>
.texte {
   min-height: 450px;
   font-size: 0.8em;
   line-height: 1.1;
   font-weight: normal;
  
}

.titre {
   text-decoration: underline;
   font-size: 1.2em;
}

.textejaune {
   color: Yellow;
}
</style>
