<template>
   <div id="pageadhesions">
      <b-container>
         <h1 class="text-center">Gestion des adhésions</h1>
         <b-button @click="UpdateCurrentAdherent">Maj adhérent</b-button>
         <b-button @click="GetSelected">Get select</b-button>
         <div class="thead-dark table-sm table-bordered table-success" ref="tableadherents"></div>
      </b-container>
   </div>
</template>

<script>
import { gsap } from "gsap";
import { db } from "../../firebaseInit";
import { collection, query, getDocs, deleteDoc, doc, setdDoc, addDoc } from "firebase/firestore/lite";
import { Tabulator } from "tabulator-tables";

export default {
   name: "pageadhesions",
   components: {},

   mounted() {
      //instantiate Tabulator when element is mounted
      this.tableadherents = new Tabulator(this.$refs.tableadherents, {
         data: this.adhesions, //link data to table
         selectable: true,
         selectableRangeMode: "click",
         layout: "fitColumns",
         columns: [
            { title: "Nom", field: "data.nom" },
            { title: "Prénom", field: "data.prenom" },
         ],
          rowSelectionChanged:function(data, rows){
           console.log(data);
    },
      });
   },
   data() {
      return {
         adhesions: [],
         tableadherents: null,
         currentadherent: {
            nom: "Le Guerroué",
            prenom: "Gaël",
         },
         deep: true,
      };
   },
   watch: {
      //update table if data changes
      adhesions: {
         handler: function (newData) {
            this.tableadherents.replaceData(newData);
         },
         deep: true,
      },
   },
   created() {
      this.GetAdherents();
   },
   methods: {
      async GetAdherents() {
         this.datefermetures = [];
         const q = query(collection(db, "adhesions"));
         let self = this;
         const querySnapshot = await getDocs(q);
         querySnapshot.forEach((doc) => {
            self.adhesions.push({ id: doc.id, data: doc.data() });
         });
         console.log(this.adhesions);

         return this.adhesions;
      },
      async UpdateCurrentAdherent() {
         console.log(this.currentadherent);

         const docRef = await addDoc(collection(db, "adhesions"), this.currentadherent);
         //recharde des dateselectionne
         await this.GetAdherents();
         // this.$refs.TableFermeture.refresh();

         console.log("Document written with ID: ", docRef.id);
      },

      async DeleteCurrentAdherent() {},

      GetSelected() {
         console.log(this.tableadherents);
         this.tableadherents.selectRow(1);
      },
   },
};
</script>

<style scoped>
#pageadhesions {
   background-image: url("../../assets/ardoise.jpg");
   color: white;
   font: 1em sans-serif;
   height: 100vh;
   width: 100%;
}

.tabulator {
   color: black;
}
</style>
