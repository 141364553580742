<template>
   <div class="ardoise">
      <table style="width: 100%; height: 100%">
         <tr style="height: 25px">
            <td class="bois" colspan="3" style="border-top-left-radius: 25px; border-top-right-radius: 25px"></td>
         </tr>
         <tr>
            <td class="bois2" style="width: 25px"></td>
            <td>
               <div class="ardoise_in">
                  <div class="contenuardoise">
                     <slot></slot>
                  </div>
               </div>
            </td>
            <td class="bois2" style="width: 25px"></td>
         </tr>
         <tr style="height: 25px">
            <td class="bois" colspan="3" style="border-bottom-left-radius: 25px; border-bottom-right-radius: 25px"></td>
         </tr>
      </table>
   </div>
</template>

<script>
export default {
   name: "Ardoise",
   data() {
      return {};
   },
   methods: {},
};
</script>

<style>
.ardoise {
   position: relative;

   z-index: 11;
   
   font-family: "erasdust";
   background-image: url("../assets/ardoise.jpg");
   color: White;
   z-index: 12;
   border-radius: 25px;
   border: none;
}

.ardoise_in {
   height: calc(100% - 0px);
   width: calc(100% - 0px);
   background-repeat: repeat;
   margin: auto;
   font-size: 40px;
   z-index: 11;
   border: none;
   overflow: hidden;
}

.contenuardoise {
   z-index: 23;
  
   font-size: 25px;
  max-height: 550px;
   overflow-x: hidden;
   overflow-y: auto;
   color: white;
   line-height: 40px;
   width: 100%;
   height : 100%;
   cursor: n-resize;
 
}

.contenuardoise::-webkit-scrollbar {
   display: none;
}

.bois {
   background-image: url("../assets/Sipo.jpg");
   box-shadow: 2px 2px 3px #555;
}

.bois2 {
   background-image: url("../assets/Sipo2.jpg");
   box-shadow: 2px 2px 3px #555;
}
</style>
