<template>
   <div class="texte ml-2 mr-2">
      <div class="titre text-center mb-4" titre>Tarifs {{ new Date().getFullYear() }}</div>

      <div class="mt-1 mb-4"><u>2 activités proposées :</u> Education et Agility</div>

      <h3><u>EDUCATION ou AGILITY </u></h3>

      <ul>
         <li>1 chien : <span class="textejaune">110 €</span></li>
         <li>2 chiens et + : <span class="textejaune">140 €</span></li>
         <li>Jeunes (-de 14 ans) : <span class="textejaune">70 €</span></li>
      </ul>
      <span>Possibilité d’inscrire plusieurs conducteurs pour un même chien sans majoration de tarif</span>

      <br />
      <b>2 activités au choix : <span class="textejaune">+20 €</span></b> soit 130€ pour un chien <br />

      <br />

      <span>Inscriptions tout au long de l'année. Durée de l'adhésion :</span><span class="textejaune"> 1 an </span>
      
   </div>
</template>

<script>
export default {};
</script>

<style scoped>
.texte {
   min-height: 450px;
   font-size: 1.0em;
   line-height: 1.1;
   font-weight: normal;

}

.titre {
   text-decoration: underline;
   font-size: 1.2em;
}

.textejaune
{
    color: Yellow;
}
</style>
