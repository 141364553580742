<template>
   <div class="texte ml-2 mr-2">
      <div class="titre text-center mb-4" titre>Commandes de croquettes {{ new Date().getFullYear() }}</div>

       Nous avons un partenaire pour l'achat de croquettes chiens et chats :
    <ul>
         <li>Nourrir comme la nature</li>
    </ul>
    <br>
    <div>Merci de vour rapprocher de Maela le Guerroué pour passer vos commandes.</div>
     <span class="textejaune">au 06.61.55.42.35</span><br>
    <span>ou par mail : </span><span><a class="textejaune" href="mailto:communicationt@club-canin-corps-nuds.fr?subject=demande de renseignements croquette">
        communication@club-canin-corps-nuds.fr</a></span>
      
   </div>
</template>

<script>
export default {};
</script>

<style scoped>
.texte {
   min-height: 450px;
   font-size: 1.0em;
   line-height: 1.1;
   font-weight: normal;

}

.titre {
   text-decoration: underline;
   font-size: 1.2em;
}

.textejaune
{
    color: Yellow;
}
</style>
