// src/firebaseInit.js
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCWlxOA7Boyg2CzKDNd_-Mx3hR7hB5p4pk",
    authDomain: "clubcanincorpsnuds-d01a5.firebaseapp.com",
    projectId: "clubcanincorpsnuds-d01a5",
    storageBucket: "clubcanincorpsnuds-d01a5.appspot.com",
    messagingSenderId: "906994306145",
    appId: "1:906994306145:web:9edc9def6cb3c10bce2e20",
    measurementId: "G-PJEPP1YZSW"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };